body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.rounded-1 {
  border-radius: .25rem!important;
}

.rounded-2 {
  border-radius: .5rem!important;
}

.rounded-3 {
  border-radius: 1rem!important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.borderless-button-link {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #007BFF;
  text-decoration: underline;
  cursor: pointer;
  outline: 0px;
}

/* set the font size in the Quill editor big enough so that ios safari doesn't zoom in when you focus the field */
.ql-container {
  font-size: 16px;
}

.powered-by {
  display: none!important;
}


.h-1 {
  height: .25rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: 1rem;
}

.h-4 {
  height: 1.5rem;
}

.h-5 {
  height: 3rem;
}
