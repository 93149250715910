.DateRangePickerInput__withBorder {
    border-radius: .25rem;
    border: 1px solid #ced4da;
    overflow: hidden;
}

.invalidDate .DateRangePickerInput__withBorder {
    border: 1px solid #dc3545;
}

.DateRangePicker_picker__fullScreenPortal {
    z-index: 2
}

.DateRangePicker_picker {
    z-index: 10
}
